<template>
	<NuxtLink
		v-if="!disabled"
		class="!no-underline stepperNodeComponent"
		:to="urlPath">
		<div
			class="group"
			:class="{ active: active }">
			<div class="iconBorder iconHoverEffect hoverEffects relative flex">
				<div class="iconContainer">
					<IconsFontAwesome :icon="icon" />
				</div>
				<StepperNodeCount :count="numProductsCount" />
			</div>

			<p class="iconLabel">{{ label }}</p>
		</div>
	</NuxtLink>

	<div v-else>
		<div
			class="group"
			:class="{ active: active }">
			<div class="iconBorder iconHoverEffect hoverEffects">
				<div class="iconContainer">
					<IconsFontAwesome :icon="icon" />
				</div>
			</div>

			<p class="iconLabel">{{ label }}</p>
		</div>
	</div>
</template>

<script setup>
	import { storeToRefs } from "pinia"
	import { useCartStore } from "~/stores/CartStore"

	const { urlPath, productTypeId } = defineProps({
		urlPath: String,
		label: String,
		icon: String,
		disabled: Boolean,
		productTypeId: String,
	})

	//Highlight this node with the active class if viewing this page
	const active = ref(false)
	const path = useRoute().path
	if (path === urlPath) {
		active.value = true
	}

	//Calculate the item count for each node
	const numProductsCount = ref(0)
	const cartStore = useCartStore()
	const isProductPage = urlPath.indexOf("products") >= 0 && productTypeId
	if (isProductPage) {
		const cartStore = useCartStore()
		const { items } = storeToRefs(cartStore)
		watch(
			items,
			() => {
				numProductsCount.value = cartStore.getNumProductsByType(productTypeId)
			},
			{ deep: true }
		)
		numProductsCount.value = cartStore.getNumProductsByType(productTypeId)
	}
</script>

<style lang="scss" scoped>
	.iconBorder {
		@apply border-stepper-primary border-2 rounded-full mx-auto;
		@apply w-[36px] h-[36px];
		@apply md:w-[44px] md:h-[44px];
		@apply lg:w-[54px] lg:h-[54px];
	}

	.iconHoverEffect {
		@apply group-hover:border-stepper-hover group-hover:text-stepper-hover group-hover:bg-stepper-hover;
	}

	.iconContainer {
		@apply group-hover:text-stepper-primary text-stepper-primary text-lg text-center;
		@apply m-auto;
		@apply text-[13pt] md:text-[14pt] lg:text-[16pt];
	}

	.iconLabel {
		@apply text-stepper-primary group-hover:text-stepper-hover text-xs font-semibold mt-2 whitespace-nowrap max-sm:hidden;
	}

	.active {
		> div {
			@apply text-stepper-hover border-stepper-hover bg-stepper-hover;

			svg {
				@apply text-stepper-primary;
			}
		}

		> p {
			@apply text-stepper-hover;
		}
	}
</style>
