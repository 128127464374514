<template>
	<div class="widgetContainer">
		<div class="flex justify-evenly">
			<template v-for="page in pagesToDisplay">
				<div
					class="stepper-item"
					:class="{ hidden: page.disabledLink }">
					<StepperNode
						:urlPath="page.urlPath"
						:disabled="page.disabledLink"
						:label="$t(`${page.navNodei18nKey}`)"
						:icon="page.fontAwesomeIcon"
						:product-type-id="getProductTypeIdForNode(page.productType)" />
				</div>
				<div class="stepper-line-container">
					<div class="stepper-line"></div>
				</div>
			</template>
		</div>
	</div>
</template>

<script setup>
	const runtimeConfig = useRuntimeConfig()
	const pages = runtimeConfig.public.PAGES

	const pagesToDisplay = pages.filter((page) => page.disabledLink !== true)

	const getProductTypeIdForNode = (productType) => {
		return productType && runtimeConfig.public.PRODUCTS[productType]
			? runtimeConfig.public.PRODUCTS[productType].productTypeId
			: ""
	}
</script>

<style lang="scss" scoped>
	.stepper-item {
		@apply w-10 sm:w-24 mx-1;
	}

	.stepper-line-container {
		@apply relative;

		&:last-child {
			@apply hidden;
		}
	}

	.stepper-line {
		@apply absolute bg-stepper-primary;
		@apply h-[2px];
		@apply w-1 left-[-2px] top-[47%];
		@apply sm:w-2 sm:top-[29%];
		@apply md:w-6 md:left-[-12px] md:top-[30%];
		@apply lg:w-20 lg:left-[-2.5em] lg:top-[33%];
	}
</style>
